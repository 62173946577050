const themeFile = {
  palette: {
    primary: {
      light: "#8f3357",
      main: "#74002e",
      dark: "#510020",
      contrastText: "#e4f9f5",
    },
    secondary: {
      light: "#efcb87",
      main: "#f4bc5a",
      dark: "#aa833e",
      contrastText: "#8f3357",
    },
    error: {
      light: "#f6c97b",
      main: "#f44336",
      dark: "#d32f2f",
    },
    text: {
      primary: "#222222",
    },
  },
  typography: {
    fontFamily: ["Playfair Display", "Lato", "Arial", "Sans serif"].join(","),
    h1: {
      fontFamily: "Monsieur La Doulaise",
    },
    h2: {
      fontFamily: "Snell Roundhand",
    },
    h3: {
      fontFamily: "Snell Roundhand",
    },
    h4: {
      fontFamily: "Snell Roundhand",
    },
    h5: {
      fontFamily: "Snell Roundhand",
    },
    h6: {
      fontFamily: "Snell Roundhand",
    },

    body1: {
      letterSpacing: "0em",
    },
    body2: {
      letterSpacing: "0em",
    },
  },
  overrides: {
    MuiStepper: {
      root: {
        background: "none",
        border: "none",
      },
    },
  },

  spreadThis: {
    cursive: {
      fontFamily: "Snell Roundhand",
    },

    rsvpButton: {
      minWidth: 100,
      backgroundColor: "rgb(248, 249, 251)",
      "&:hover": {
        backgroundColor: "rgb(248, 249, 251, 0.76)",
      },
    },
    styledTextField: {
      margin: "5px auto 5px auto",
      backgroundColor: "rgb(248, 249, 251)",
      borderRadius: 0,
      [`& fieldset`]: {
        borderRadius: 0,
      },
    },
    styledTextFieldError: {
      margin: "5px auto 5px auto",
      [`& input`]: { color: "#023C40" },
      [`& fieldset`]: {
        borderRadius: 0,
        backgroundColor: "rgba(245,221,219,0.5)",
      },
    },
    flexBox: {
      display: "flex",
      alignItems: "center",
    },
    flexBoxCentered: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    flexBoxCenteredColumn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    flexBoxSpaced: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flexBoxSpacedColumn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    flexBoxSpaceAround: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-around",
      alignItems: "center",
      alignContent: "center",
    },
    flexBoxSpaceAroundColumn: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-around",
      alignItems: "center",
      alignContent: "center",
      flexDirection: "column",
    },
    flexBoxEnd: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    flexBoxEndColumn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexDirection: "column",
    },
    loadingSpinner: {
      position: "absolute",
    },
    form: {
      textAlign: "center",
    },
    titleLine: {
      width: 60,
      height: 4,
      backgroundColor: "#74002e",
      marginBottom: 8,
    },
    cardBox: {
      padding: "48px 32px",
      height: "100%",
    },
    flexBoxCenteredColumnFull: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 100px)",
      flexDirection: "vertical",
      width: "100%",
    },
    roundedButton: {
      textTransform: "none",
      borderRadius: 25,
    },
    dialogHeader: {
      padding: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#e9dbc1",
      color: "#8f3357",
    },
    hoverContainer: {
      borderRadius: 10,
      padding: 8,
      margin: "4px 0px",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(212,212,212,0.2)",
      },
    },
    hoverContainerAlt: {
      borderRadius: 10,
      padding: 8,
      margin: "4px 0px",
      backgroundColor: "rgba(212,212,212,0.15)",
      "&:hover": {
        backgroundColor: "rgba(212,212,212,0.2)",
      },
    },
    selectedContainer: {
      borderRadius: 10,
      padding: 8,
      margin: "12px 0px",
      boxShadow: "#efcb87 0px 0px 12px",
      outline: "none",
      transition: "0.3s",
      // outline: "1px solid rgba(212,212,212,0.3)",
    },
    chipOrjBorder: {
      margin: 4,
      backgroundColor: "rgb(254	241	222)",
      color: "rgb(112	80	39)",
      border: "1px solid rgb(112	80	39)",
    },
    chipRedBorder: {
      margin: 4,
      color: "rgb(233,45,43)",
      backgroundColor: "rgba(219,17,32,0.1)",
      border: "1px solid rgb(233,45,43)",
    },
    chipBlueBorder: {
      margin: 4,
      backgroundColor: "rgb(227	241	253)",
      color: "rgb(41	70	100)",
      border: "1px solid rgb(41	70	100)",
    },
    chipGreenBorder: {
      margin: 4,
      backgroundColor: "rgb(233	245	232)",
      color: "rgb(63	89	60)",
      border: "1px solid rgb(63	89	60)",
    },
    backgroundGreen: {
      backgroundColor: "rgb(233	245	232)",
      color: "rgb(63	89	60)",
      padding: 8,
      borderRadius: 10,
      margin: "8px 0px",
    },
    backgroundOrj: {
      backgroundColor: "rgb(254	241	222)",
      color: "rgb(112	80	39)",
      padding: 8,
      borderRadius: 10,
      margin: "8px 0px",
    },
    backgroundRed: {
      backgroundColor: "rgba(219,17,32,0.1)",
      color: "rgb(233,45,43)",
      padding: 8,
      borderRadius: 10,
      margin: "8px 0px",
    },

    backgroundBlue: {
      backgroundColor: "rgb(227	241	253)",
      color: "rgb(41	70	100)",
      padding: 8,
      borderRadius: 10,
      margin: "8px 0px",
    },
    progress: {
      position: "absolute",
    },
    grayContainer: {
      backgroundColor: "rgba(212,212,212,0.2)",
    },
  },
};

export default themeFile;
