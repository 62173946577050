import React, { Component } from "react";
import firebase from "../../Firebase";

// Redux imports
import store from "../../redux/store";
import { SET_INVITE } from "../../redux/types";

// CSS imports
import "./Home.css";
import "../../App.css";

// Component imports
import HomeContainer from "./HomeContainer";
import { connect } from "react-redux";

export class home extends Component {
  componentDidMount() {
    const queryParams = this.props.location.search;
    if (queryParams) this._validateId(queryParams);
  }

  // componentDidUpdate() {
  //   if (this.props.isAdmin && Object.keys(this.props.invite).length > 0) {
  //     this.props.history.push("/admin");
  //   }
  // }

  async _validateId(queryParams) {
    const inviteId = this._parseId(queryParams);
    if (inviteId) {
      const db = firebase.firestore();
      const inviteDoc = await db.doc(`/invites/${inviteId}`).get();
      if (inviteDoc.exists) {
        const invite = { ...inviteDoc.data(), id: inviteDoc.id };
        store.dispatch({ type: SET_INVITE, payload: invite });
      } else {
        this.props.history.replace({ pathname: "/" });
      }
    }
  }

  _parseId(queryParams) {
    return queryParams.split("id=")[1];
  }
  render() {
    return <HomeContainer history={this.props.history} />;
  }
}

const mapStateToProps = (state) => ({
  isAdmin: state.main.isAdmin,
  invite: state.main.invite,
});

export default connect(mapStateToProps)(home);
