import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

// Component imports
import HeroImg from "../../assets/images/rsvp-sm.webp";
import RsvpContainer from "./components/RsvpContainer";

// MUI imports
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

smoothscroll.polyfill();

const pd = ["rsvp"];
function HomeContainer(props) {
  const location = useLocation();

  const searchParams = useMemo(
    () => location.search.split("pd=")[1],
    [location]
  );

  const inviteId = useMemo(() => location.search.split("id=")[1], [location]);
  useEffect(() => {
    if (pd.includes(searchParams) || inviteId) {
      const element = document.getElementById("rsvp");
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  }, []);

  function scrollToRSVP() {
    const rsvpContainer = document.getElementById("rsvp");
    window.scrollTo({ top: rsvpContainer.offsetTop, behavior: "smooth" });
  }

  return (
    <div className="responsive-container">
      <div style={{ transition: "0.5s" }} className={`parallax-container`}>
        <img src={HeroImg} alt="hero" className={`parallax`} />
      </div>
      <div className="landing">
        <Typography color="secondary" variant="h1" className="home-header">
          Vanessa {"&"} Matthew
        </Typography>
        <div>
          <Button
            onClick={scrollToRSVP}
            color="secondary"
            variant="outlined"
            sx={{
              width: 150,
              boxShadow: "none",
              backgroundColor: "secondary.main",
              color: "#fff",
              fontFamily: "Playfair Display",
              "&:hover": {
                backgroundColor: "white",
                color: "secondary.main",
              },
              borderRadius: 0,
            }}
          >
            RSVP
          </Button>
        </div>
      </div>
      <div
        id="rsvp"
        className="flexbox-centered-all gr-1"
        style={{ minHeight: "100vh" }}
      >
        <div className="stn-box" style={{ position: "relative" }}>
          <div className="mv-border" />
          <div className="rsvp-container">
            <RsvpContainer history={props.history} />
          </div>
        </div>
      </div>
      <div
        className="flexbox-centered-all gr-2"
        style={{ minHeight: "50vh" }}
      ></div>
    </div>
  );
}

export default HomeContainer;
