import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Slide from "@mui/material/Slide";

const ScrollHandler = (props) => {
  const { window, navTheme } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 0,
  });

  let navColor = "rgba(254,254,254,0.45)";
  if (navTheme === "dark?") navColor = "rgba(47,47,47,0.45)";

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {React.cloneElement(props.children, {
        style: {
          backgroundColor: trigger ? navColor : navColor,
          //   opacity: trigger ? 0.5 : 1,
          //   color: trigger ? "white" : "black",

          transition: trigger ? "0.3s" : "0.3s",
          //   padding: "10px 0px",
        },
      })}
    </Slide>
  );
};

const ScrollToHide = (props) => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

export default ScrollToHide;
