import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import dayjs from "dayjs";
import Firebase from "./Firebase";
import { SET_ADMIN } from "./redux/types";

import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import themeFile from "./themeFile";

// Component imports
import Navbar from "./components/navbar/Navbar";
import AlertMarkup from "./components/alert/AlertMarkup";

// redux imports
import { Provider } from "react-redux";
import store from "./redux/store";

// Pages imports

import home from "./pages/home/home";
const giftRegistryTest = React.lazy(() =>
  import("./pages/giftRegistry/giftRegistryTest")
);
// const program = React.lazy(() => import("./pages/program/program"));
const photos = React.lazy(() => import("./pages/photos/photos"));
const admin = React.lazy(() => import("./pages/admin/admin"));
const checkin = React.lazy(() => import("./pages/checkin/checkin"));

const theme = responsiveFontSizes(createTheme(themeFile));

export class App extends Component {
  componentDidMount() {
    this._validateUserAuth();
  }

  async _validateUserAuth() {
    const validationCodeMaster = localStorage.getItem("validationCodeMaster");
    const expiryTime = localStorage.getItem("expiryTime");
    const adminUser = localStorage.getItem("adminUser");

    const isExpired = dayjs().isAfter(parseInt(expiryTime));

    if (Boolean(expiryTime) && !isExpired) {
      const db = Firebase.firestore();

      const validationCodeDoc = await db.doc("/masterCode/validation").get();

      if (
        validationCodeMaster === validationCodeDoc.data().validationCodeMaster
      )
        store.dispatch({
          type: SET_ADMIN,
          payload: { validationCodeMaster, adminUser },
        });
    } else {
      this._removeStorage();
    }
  }

  _removeStorage() {
    localStorage.removeItem("validationCodeMaster");
    localStorage.removeItem("adminUser");
    localStorage.removeItem("expiryTime");
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <Router>
              <React.Suspense fallback={<p></p>}>
                <div>
                  <Navbar />
                  <AlertMarkup />

                  <div className="container">
                    <Switch>
                      <Route exact path="/" component={home} />

                      {/* <Route exact path="/program" component={program} /> */}
                      <Route exact path="/photos" component={photos} />
                      <Route
                        exact
                        path="/gift-registry"
                        component={giftRegistryTest}
                      />
                      <Route
                        path="/gift-registry/item"
                        component={giftRegistryTest}
                      />
                      <Route
                        path="/gift-registry/donation"
                        component={giftRegistryTest}
                      />
                      <Route exact path="/admin" component={admin} />
                      <Route exact path="/checkin" component={checkin} />
                      <Redirect to="/" />
                    </Switch>
                  </div>
                </div>
              </React.Suspense>
            </Router>
          </Provider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
