import firebase from "firebase/app";

// import "firebase/compat/auth";
// import "firebase/compat/firestore";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAo4TGvCls88jTU3FuC8ta-cf7DfNE5GVw",
  authDomain: "mybabethekoala.firebaseapp.com",
  projectId: "mybabethekoala",
  storageBucket: "mybabethekoala.appspot.com",
  messagingSenderId: "458093224223",
  appId: "1:458093224223:web:e0b7460226fdb469b3502a",
  measurementId: "G-WSDF6013V6",
};

export default !firebase.apps.length
  ? firebase.initializeApp(config)
  : firebase.app();
