/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function Container(props) {
  return (
    <Box
      p={props.p}
      display="flex"
      justifyContent={props.centeredAll ? "center" : "flex-start"}
      alignItems={props.centered ? "center" : "flex-start"}
      style={{ flexDirection: "column", ...props.style }}
      component={props.component}
      className={props.className}
    >
      {props.children}
    </Box>
  );
}

Container.propTypes = {
  centeredAll: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  component: PropTypes.elementType,
  p: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};
export default Container;
