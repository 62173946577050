import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import store from "../../redux/store";
import { UNSET_ALERT } from "../../redux/types";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export class AlertMarkup extends Component {
  componentDidUpdate(prevProps) {
    const { isAlert } = this.props.alert;
    if (isAlert && !prevProps.isAlert) {
      const timeout = setTimeout(() => this.handleCloseAlert(), 6000);
      this.timeout = timeout;
    }
  }

  handleCloseAlert = () => {
    store.dispatch({ type: UNSET_ALERT });
  };
  render() {
    const {
      alert: { isAlert, message, type },
    } = this.props;

    const online = navigator.onLine;

    return (
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isAlert || !online}
        key={message}
      >
        <Alert
          onClose={this.handleCloseAlert}
          severity={online ? type.toLowerCase() : "error"}
        >
          {online
            ? message
            : "There's a problem with your connection right now."}
        </Alert>
      </Snackbar>
    );
  }
}

AlertMarkup.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.main.alert,
});

export default connect(mapStateToProps)(AlertMarkup);
