import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Component imports
import ScrollToHide from "../hooks/ScrollToHide";

// CSS imports
import "./Navbar.css";

// MUI Imports
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";

const styles = (theme) => ({
  ...theme.spreadThis,
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `100%`,
    },
    zIndex: theme.zIndex.drawer + 1,
    // backgroundColor: "rgb(254,254,254)",
    boxShadow: "none",
  },
  toolBar: {
    width: "100vw",
    minHeight: "50px",
    padding: "0px 50px",
    [theme.breakpoints.up("sm")]: {
      height: 90,
      padding: "0px 100px",
    },
    [theme.breakpoints.up("md")]: {
      height: 90,
      padding: "0px 200px",
    },
  },
  listLink: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
});

function Navbar(props) {
  const { classes } = props;
  const location = useLocation();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ScrollToHide navTheme={props.navTheme}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar
            className={`${classes.toolBar} ${classes.flexBoxSpaceAround}`}
          >
            {location.pathname !== "/" ? (
              <Typography
                color="secondary"
                className={`navLink ${classes.listLink} ${
                  props.navTheme === "dark?" && "navLink-dark"
                }`}
                component={RouterLink}
                to={`/?pd=rsvp`}
              >
                RSVP
              </Typography>
            ) : (
              <Button className="txt-button">
                <Typography
                  component={Link}
                  to="rsvp"
                  spy={true}
                  smooth={true}
                  className={`navLink ${classes.listLink} ${
                    props.navTheme === "dark?" && "navLink-dark"
                  }`}
                >
                  RSVP
                </Typography>
              </Button>
            )}

            <Typography
              color="secondary"
              className={`navLink ${classes.listLink} ${
                props.navTheme === "dark?" && "navLink-dark"
              }`}
              component={RouterLink}
              to={`/photos`}
            >
              Photos
            </Typography>
            <Typography
              color="secondary"
              className={`navLink ${classes.listLink} ${
                props.navTheme === "dark?" && "navLink-dark"
              }`}
              component={RouterLink}
              to={`/gift-registry`}
            >
              Registry
            </Typography>
          </Toolbar>
        </AppBar>
      </ScrollToHide>
    </div>
  );
}

Navbar.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  navTheme: state.main.navTheme,
});
export default connect(mapStateToProps)(withStyles(styles)(Navbar));
