import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import firebase from "../../../Firebase";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const styles = (theme) => ({
  ...theme.spreadThis,
  helperText: {
    textAlign: "center",
  },
});

export class SearchByCode extends Component {
  state = { errors: {}, code: "", disableWhileLoad: false };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Action methods
  _updateCode = (e) => {
    let _value = e.target.value.toUpperCase();
    if (_value.length > 6) _value = _value.slice(0, 6);
    this.setState({
      code: _value.trim() !== "" ? _value : "",
      errors: {
        rsvp: _value.trim() === "" && "Please enter your unique invite code",
      },
    });
  };

  _getRSVP = async (e) => {
    e.preventDefault();
    const code = this.state.code;
    const db = firebase.firestore();

    const queryRef = db
      .collection("invites")
      .where("code", "==", code)
      .limit(1);

    try {
      await this.setStateAsync({ disableWhileLoad: true });
      const invitesSnapshot = await queryRef.get();
      if (invitesSnapshot.empty) {
        this.setState({
          disableWhileLoad: false,
          errors: { rsvp: "You have entered an invalid code" },
        });
      } else {
        let invite = {};
        invitesSnapshot.forEach(
          (_invite) => (invite = { ..._invite.data(), id: _invite.id })
        );
        this.props.onSelect(invite);
      }
    } catch (err) {
      this.setState({ disableWhileLoad: false });
    }
  };

  render() {
    const { errors, code, disableWhileLoad } = this.state;
    const { classes } = this.props;
    return (
      <Fade in={true}>
        <div className="sd-container">
          <Typography
            variant="h3"
            align="center"
            style={{ marginBottom: "3rem" }}
          >
            Type your Invite Code
          </Typography>
          {/* <Typography color="textSecondary" sx={{ marginBottom: 3 }}>
            Please enter 6 character code included with your invite or scan your
            QR code to search for your invite.
          </Typography> */}
          <form onSubmit={this._getRSVP}>
            <TextField
              value={code}
              name="code"
              color="secondary"
              onChange={this._updateCode}
              placeholder="ENTER CODE"
              className={
                errors.rsvp
                  ? classes.styledTextFieldError
                  : classes.styledTextField
              }
              error={Boolean(errors.rsvp)}
              helperText={errors.rsvp}
              size="small"
              fullWidth
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
            <div className="flexbox-centered-all">
              <Button
                type="submit"
                variant="contained"
                color={errors.rsvp ? "error" : "secondary"}
                disabled={disableWhileLoad}
                sx={{
                  width: 150,
                  borderRadius: 0,
                  boxShadow: "none",
                  color: "#fff",
                  marginTop: "2rem",
                }}
              >
                Search{" "}
                {disableWhileLoad && (
                  <CircularProgress
                    thickness={2}
                    className={classes.loadingSpinner}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      </Fade>
    );
  }
}

SearchByCode.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchByCode);
