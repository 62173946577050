import {
  SET_ADMIN,
  SET_INVITE,
  SET_UNAUTHENTICATED,
  UPDATE_INVITE,
  SET_ALERT,
  UNSET_ALERT,
  SET_NAV_THEME,
  UNSET_NAV_THEME,
} from "./types";

const initialState = {
  isAdmin: false,
  validationCodeMaster: "",
  adminUser: "",
  authenticated: false,
  invite: {},
  navTheme: "light",
  alert: {
    message: "",
    isAlert: false,
    type: "",
  },
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_ADMIN:
      return {
        ...state,
        isAdmin: true,
        ...action.payload,
      };
    case SET_INVITE:
      return {
        ...state,
        authenticated: true,
        invite: action.payload,
      };
    case UPDATE_INVITE:
      return {
        ...state,
        authenticated: true,
        invite: { ...state.invite, ...action.payload },
      };
    case SET_ALERT:
      return {
        ...state,
        alert: {
          ...action.payload,
          isAlert: true,
        },
      };
    case UNSET_ALERT:
      return {
        ...state,
        alert: {
          ...initialState.alert,
        },
      };
    case SET_NAV_THEME:
      return {
        ...state,
        navTheme: action.payload,
      };
    case UNSET_NAV_THEME:
      return {
        ...state,
        navTheme: initialState.navTheme,
      };
    default:
      return state;
  }
}
