import React, { useState, useEffect } from "react";
import Firebase from "../../../Firebase";
import { useDispatch } from "react-redux";
import { SET_ALERT, SET_INVITE } from "../../../redux/types";

// Component imports
import { capitalizeAllFirstChar, isEmpty } from "../../../helpFunctions";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
}));

function RsvpGroup({ invite }) {
  // const [rsvpAll, setRsvpAll] = React.useState(false);
  const [disableWhileLoad, setDisableWhileLoad] = useState(true);
  const [errors, setErrors] = useState({});

  const [attendees, setAttendees] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [dietary, setDietary] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (invite.attendees.every((attendee) => attendee.status === ""))
      setDisableWhileLoad(false);

    setAttendees(invite.attendees);
    if (invite.dietary) setDietary(invite.dietary);
  }, []);

  const updateStatusSingle = (e, index) => {
    let _attendees = attendees;
    _attendees[index] = {
      ..._attendees[index],
      status: e.target.value,
      event: e.target.value === "accepted" ? ["ceremony", "reception"] : [],
    };
    setAttendees(_attendees);
    setRerender(!rerender);
  };

  const updateEventSingle = (e, index) => {
    let _attendees = attendees;

    const event = _attendees[index].event;

    if (event.includes(e.target.value)) {
      const eventInd = event.findIndex((ev) => ev === e.target.value);
      event.splice(eventInd, 1);
    } else {
      event.push(e.target.value);
    }

    _attendees[index] = { ..._attendees[index], event };
    setAttendees(_attendees);
    setRerender(!rerender);
  };

  const updateMealSingle = (e, index) => {
    let _attendees = attendees;
    const meal = e.target.value;

    _attendees[index].meal = meal;
    setAttendees(_attendees);
    setRerender(!rerender);
  };

  const handleUpdateInvite = async () => {
    setDisableWhileLoad(true);
    setErrors({});
    const isValid = validateInput();
    if (isValid) {
      const updatedInvite = setInvite();
      await updateFBInvite(updatedInvite);
      await updateNotification(updatedInvite);
    } else {
      setDisableWhileLoad(false);
    }
  };

  const updateNotification = async () => {
    const db = Firebase.firestore();

    const notificationRef = db.collection("notifications");

    try {
      await notificationRef.add({
        refId: invite.id,
        groupName: invite.groupName,
        type: "invite",
        status: "updated",
        createdAt: new Date().toISOString(),
      });
      return;
    } catch (err) {
      console.error(err);
    }
  };

  const updateFBInvite = async (updatedInvite) => {
    const db = Firebase.firestore();

    try {
      await db.doc(`/invites/${invite.id}`).update(updatedInvite);
      const _invite = { ...invite, ...updatedInvite };
      dispatch({ type: SET_INVITE, payload: _invite });
      dispatch({
        type: SET_ALERT,
        payload: {
          message: "Successfully updated your invite",
          type: "success",
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setDisableWhileLoad(false);
    }
  };

  const setInvite = () => {
    const updateInvite = {
      numResponded: attendees.filter((attendee) => !isEmpty(attendee.status))
        .length,
      attendees,
      dietary,
    };

    updateInvite.status = attendees.every(
      (attendee) => attendee.status === "declined"
    )
      ? "declined"
      : "accepted";

    return updateInvite;
  };

  const validateInput = () => {
    const _errors = {};

    // if (rsvpAll && isEmpty(status)) _errors.status = "Invalid RSVP response";
    // else
    if (!attendees.every((attendee) => !isEmpty(attendee.status)))
      _errors.status = "Invalid RSVP response. Please RSVP for all guests.";
    if (
      attendees.findIndex(
        (attendee) =>
          attendee.status === "accepted" && attendee.event.length < 1
      ) > -1
    ) {
      _errors.event =
        "You must select at least one event for every accepted guest.";
    }
    attendees.forEach((attendee) => {
      if (attendee.event.includes("reception") && isEmpty(attendee.meal)) {
        _errors.meal =
          "You must select an entrée for all guests attending the reception.";
      }
    });
    setErrors(_errors);
    return Object.keys(_errors).length < 1;
  };

  return (
    <>
      <Box className="rsvp-options" justifyContent="center" p={2}>
        <Fade in={true} mountOnEnter unmountOnExit>
          <Grid container justifyContent="center" spacing={2}>
            {attendees.map((attendee, index) => (
              <Grid item xs={12} sm={10} key={index}>
                <Box
                  sx={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    marginTop: 2,
                    padding: "2rem 1.5rem",
                  }}
                >
                  <Typography align="center" variant="h4">
                    {capitalizeAllFirstChar(attendee.title)}{" "}
                    {capitalizeAllFirstChar(attendee.firstName)}{" "}
                    {capitalizeAllFirstChar(attendee.lastName)}
                  </Typography>

                  {/* <Fade in={!rsvpAll} timeout={350} mountOnEnter unmountOnExit> */}
                  <RadioGroup
                    row
                    aria-label="status"
                    name="status"
                    value={attendees[index].status}
                    onChange={(e) => updateStatusSingle(e, index)}
                  >
                    <Grid
                      container
                      spacing={0}
                      justifyContent="space-evenly"
                      sx={{ marginTop: 1 }}
                    >
                      <Grid item xs={"auto"} sm={"auto"}>
                        <FormControlLabel
                          value="accepted"
                          control={
                            <Radio
                              color={"secondary"}
                              disabled={disableWhileLoad}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              color={
                                attendees[index].status === "accepted"
                                  ? "secondary"
                                  : "textSecondary"
                              }
                            >
                              Happily Accepts
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={"auto"} sm={"auto"}>
                        <FormControlLabel
                          value="declined"
                          control={
                            <Radio
                              color={"secondary"}
                              disabled={disableWhileLoad}
                            />
                          }
                          label={
                            <Typography
                              color={
                                attendees[index].status === "declined"
                                  ? "secondary"
                                  : "textSecondary"
                              }
                              variant="body2"
                            >
                              Regretfully Declines
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {/* </Fade> */}
                  <Fade
                    in={attendee.status === "accepted"}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Box
                      sx={{
                        borderTop: "1px solid #f4bc5a",
                        marginTop: 2,
                        paddingTop: 2,
                      }}
                    >
                      <Typography align="center" variant="h6">
                        I will be attending the...
                      </Typography>

                      <Grid container spacing={0} justifyContent="space-evenly">
                        <Grid item xs={"auto"} sm={"auto"}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color={"secondary"}
                                value="ceremony"
                                checked={attendees[index].event.includes(
                                  "ceremony"
                                )}
                                onChange={(e) => updateEventSingle(e, index)}
                                disabled={disableWhileLoad}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                color={
                                  attendees[index].event.includes("ceremony")
                                    ? "secondary"
                                    : "textSecondary"
                                }
                              >
                                Ceremony (4:30)
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={"auto"} sm={"auto"}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value="reception"
                                color={"secondary"}
                                checked={attendees[index].event.includes(
                                  "reception"
                                )}
                                onChange={(e) => updateEventSingle(e, index)}
                                disabled={disableWhileLoad}
                              />
                            }
                            label={
                              <Typography
                                color={
                                  attendees[index].event.includes("reception")
                                    ? "secondary"
                                    : "textSecondary"
                                }
                                variant="body2"
                              >
                                Reception (6:30)
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Fade>
                  <Fade
                    in={attendee.event.includes("reception")}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Box
                      sx={{
                        paddingTop: 2,
                      }}
                    >
                      <Typography align="center" variant="h6">
                        My choice of entrée is...
                      </Typography>

                      <RadioGroup
                        row
                        aria-label="meal"
                        name="meal"
                        value={attendees[index].meal}
                        onChange={(e) => updateMealSingle(e, index)}
                        style={{ marginBottom: "2rem" }}
                      >
                        <Grid
                          container
                          spacing={0}
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={"auto"} sm={"auto"}>
                            <FormControlLabel
                              value="Halibut"
                              control={
                                <Radio
                                  color={"secondary"}
                                  disabled={disableWhileLoad}
                                />
                              }
                              label={
                                <Typography
                                  color={
                                    attendees[index].meal === "Halibut"
                                      ? "secondary"
                                      : "textSecondary"
                                  }
                                  variant="body2"
                                  style={{ marginBottom: 0 }}
                                >
                                  West Coast Halibut
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={"auto"} sm={"auto"}>
                            <FormControlLabel
                              value="Steak"
                              control={
                                <Radio
                                  color={"secondary"}
                                  disabled={disableWhileLoad}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  style={{ marginBottom: 0 }}
                                  color={
                                    attendees[index].meal === "Steak"
                                      ? "secondary"
                                      : "textSecondary"
                                  }
                                >
                                  New York Steak
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </Box>
                  </Fade>
                </Box>
              </Grid>
            ))}
            <Fade
              in={
                attendees.findIndex((attendee) => !isEmpty(attendee.meal)) > -1
              }
              mountOnEnter
              unmountOnExit
            >
              <Grid item xs={12} sm={10}>
                <Box
                  sx={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    marginTop: 2,
                    padding: "1rem 1.5rem",
                  }}
                >
                  <TextField
                    name="dietary"
                    value={dietary}
                    onChange={(e) => {
                      if (e.target.value.length < 46)
                        setDietary(e.target.value);
                    }}
                    fullWidth
                    color="secondary"
                    size="small"
                    multiline
                    minRows={3}
                    maxRows={5}
                    label="Dietary Restrictions"
                    placeholder="Please enter your dietary restrictions (45 char max)"
                    className={
                      errors.dietary
                        ? classes.styledTextFieldError
                        : classes.styledTextField
                    }
                    error={Boolean(errors.dietary)}
                    helperText={errors.dietary}
                    variant="outlined"
                    disabled={disableWhileLoad}
                  />
                </Box>
              </Grid>
            </Fade>
          </Grid>
        </Fade>
      </Box>

      <Typography
        variant="body2"
        align="center"
        style={{ margin: "1rem 0rem" }}
      >
        Attire: Suits for men and dark or pastel formal dresses for women
      </Typography>
      <Fade
        in={
          Boolean(errors.status) ||
          Boolean(errors.event) ||
          Boolean(errors.meal)
        }
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Typography
            component="div"
            variant="caption"
            color="error"
            align="center"
          >
            {errors.status}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="error"
            align="center"
          >
            {errors.event}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="error"
            align="center"
          >
            {errors.meal}
          </Typography>
        </div>
      </Fade>
      <div className={classes.flexBoxCentered} style={{ marginTop: "1rem" }}>
        <Button
          variant="outlined"
          disabled={disableWhileLoad}
          type="submit"
          color="secondary"
          onClick={handleUpdateInvite}
          sx={{
            borderRadius: 0,
            boxShadow: "none",
            color: "#fff",
            width: 100,
            backgroundColor: "secondary.main",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#fff",
              color: "secondary.main",
            },
          }}
        >
          confirm
        </Button>
      </div>
    </>
  );
}

export default RsvpGroup;
