import React from "react";
import { useDispatch } from "react-redux";
import { SET_INVITE } from "../../../redux/types";

import SearchByName from "./SearchByName";
import SearchByCode from "./SearchByCode";
import Button from "@mui/material/Button";

function ToggleSearch(props) {
  const dispatch = useDispatch();
  const [toggleSearchByName, setToggleSearchByName] = React.useState(false);

  const _toggleSearch = () => {
    setToggleSearchByName(!toggleSearchByName);
  };

  const setInvite = (invite) => {
    dispatch({ type: SET_INVITE, payload: invite });
  };
  return (
    <>
      {!toggleSearchByName ? (
        <SearchByCode _searchByName={_toggleSearch} onSelect={setInvite} />
      ) : (
        <SearchByName
          _searchByCode={_toggleSearch}
          onSelect={setInvite}
          hide={props.hide}
        />
      )}
      {!props.hide && (
        <Button
          color="secondary"
          onClick={() => setToggleSearchByName(!toggleSearchByName)}
          sx={{
            width: 150,
            boxShadow: "none",

            fontFamily: "Playfair Display",

            borderRadius: 0,
            marginTop: "2rem",
          }}
        >
          {toggleSearchByName ? "Search By Code" : "Search by Name"}
        </Button>
      )}
    </>
  );
}

export default ToggleSearch;
