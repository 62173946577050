import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import { capitalizeAllFirstChar } from "../../../helpFunctions";
import RsvpInd from "./RsvpInd";
import RsvpGroup from "./RsvpGroup";

import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

export class RsvpAuthed extends Component {
  // Initial Load methods
  componentDidMount() {
    this._setInitial();
  }

  _setInitial() {
    const { invite } = this.props;
    this.props.history.push({
      pathname: "/",
      search: `?id=${invite.id}`,
    });
  }

  render() {
    const { invite } = this.props;
    return (
      <Fade in>
        <div className="sd-container">
          <div className="flexbox-centered-all">
            <Typography
              variant="h3"
              align="center"
              style={{ marginTop: 16, marginBottom: 0 }}
            >
              {capitalizeAllFirstChar(invite.groupName)}
            </Typography>
            <Typography
              align="center"
              sx={{
                borderBottom: "2px solid",
                borderColor: "secondary.main",
                width: "fit-content",
              }}
            >
              {`${invite.maxAttendees} Seat(s)`}
            </Typography>
          </div>

          <Typography align="justify" variant="body2" sx={{ marginTop: 4 }}>
            We would be honoured if you could join us as we make our vows and
            celebrate our forever.
          </Typography>
          {invite.attendees.every((attendee) => attendee.status !== "") && (
            <Typography variant="h5" align="center" sx={{ marginTop: 2 }}>
              Updates to RSVP is now closed
            </Typography>
          )}
          <div className="swirl-border" style={{ marginTop: 36 }} />

          {invite.type === "individual" ? (
            <RsvpInd invite={invite} />
          ) : (
            <RsvpGroup invite={invite} />
          )}
        </div>
      </Fade>
    );
  }
}

RsvpAuthed.propTypes = {
  invite: PropTypes.object.isRequired,
};

export default RsvpAuthed;
