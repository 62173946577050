import React, { Component } from "react";
import PropTypes from "prop-types";

// Component imports
import firebase from "../../../Firebase";
import {
  capitalizeAllFirstChar,
  isEmpty,
  splitStringToArray,
} from "../../../helpFunctions";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class SearchByName extends Component {
  state = {
    nameParams: "",
    disableWhileLoad: false,
    invites: [],
    isLoading: false,
    isQueried: false,
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  // Action methods
  _updateNameParams = async (e) => {
    const value = e.target.value;
    await this.setStateAsync({ nameParams: !isEmpty(value) ? value : "" });
    if (!isEmpty(value)) {
      this._queryAttendeesByName();
    } else {
      this.setState({ invites: [], isQueried: false, isLoading: false });
    }
  };

  _queryAttendeesByName = async () => {
    const { nameParams } = this.state;
    const db = firebase.firestore();

    const queryParams = splitStringToArray(nameParams);

    const queryKeywordRef = db
      .collection("invites")
      .where("keyTermsQuery", "array-contains-any", queryParams);

    try {
      await this.setStateAsync({ isLoading: true, isQueried: false });

      const invitesKeywordSnapshot = await queryKeywordRef.get();

      if (invitesKeywordSnapshot.empty) {
        this.setState({ isQueried: true, invites: [], isLoading: false });
      } else {
        let _invites = [];

        invitesKeywordSnapshot.forEach((attendee) => {
          _invites.push({ ...attendee.data(), id: attendee.id });
        });

        _invites = _invites.filter((invite) =>
          queryParams.every(
            (param) =>
              invite.keyTermsQuery.includes(param) ||
              invite.keyTermsQuery.find((term) => term.startsWith(param))
          )
        );

        const invites = _invites.filter(
          (attendee, index, self) =>
            index === self.findIndex((t) => t.id === attendee.id)
        );

        if (invites.length < 16) {
          this.setState({ invites, isQueried: true, isLoading: false });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  _selectAttendee = (e) => {
    this.props.onSelect(this.state.invites[e.currentTarget.value]);
  };

  render() {
    const { classes } = this.props;
    const { isLoading, nameParams, isQueried, invites } = this.state;

    return (
      <Fade in>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Typography
              align="center"
              color="textSecondary"
              style={{ marginBottom: "2rem" }}
            >
              Please enter the first or last name of one member of your party
              below.
            </Typography>
            <TextField
              value={nameParams}
              onChange={this._updateNameParams}
              className={classes.styledTextField}
              color="secondary"
              placeholder="First Name or Last Name"
              size="small"
              fullWidth
            />
            <Fade in={isQueried} mountOnEnter unmountOnExit>
              <div
                className={classes.flexBoxCenteredColumn}
                style={{ margin: "8px 0px" }}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" size={50} thickness={2} />
                ) : (
                  isQueried &&
                  (invites.length < 1 ? (
                    <Typography style={{ margin: "4px 0px", fontWeight: 400 }}>
                      No invites found
                    </Typography>
                  ) : (
                    <div>
                      <Typography
                        align="center"
                        style={{ margin: "4px 0px", fontWeight: 400 }}
                      >
                        Are any of these you?
                      </Typography>

                      {invites.map((attendee, ind) => {
                        return (
                          <div
                            className={classes.flexBoxCenteredColumn}
                            key={attendee.code}
                          >
                            <Button
                              style={{
                                textTransform: "none",
                                minWidth: 175,
                                margin: "4px 0px",
                              }}
                              value={ind}
                              size="large"
                              onClick={this._selectAttendee}
                            >
                              <Typography variant="body2" align="center">
                                {capitalizeAllFirstChar(attendee.groupName)}
                              </Typography>
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  ))
                )}
              </div>
            </Fade>
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

SearchByName.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  hide: PropTypes.bool,
};

export default withStyles(styles)(SearchByName);
