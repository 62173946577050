import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux imports
import { connect } from "react-redux";
import store from "../../../redux/store";
import { SET_INVITE, UPDATE_INVITE, SET_ALERT } from "../../../redux/types";

// Component imports
import Container from "../../../components/containers/Container";
import ToggleSearch from "./ToggleSearch";
import RsvpAuthed from "./RsvpAuthed";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  ...theme.spreadThis,
});

export class RsvpContainer extends Component {
  state = { errors: {} };

  _openDialog = () => {
    this.setState({ open: true });
  };

  _closeDialog = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    this.setState({ open: false });
  };

  setInvite = (invite) => {
    store.dispatch({ type: SET_INVITE, payload: invite });
  };

  _onUpdate = (update) => {
    store.dispatch({ type: UPDATE_INVITE, payload: update });
    store.dispatch({
      type: SET_ALERT,
      payload: { message: "Invite updated!", type: "success" },
    });
  };

  render() {
    const {
      main: { authenticated, invite },
    } = this.props;
    return (
      <Container
        centered
        centeredAll
        style={{ transition: "0.3s", padding: "2rem" }}
      >
        {!authenticated ? (
          <ToggleSearch hide />
        ) : (
          <RsvpAuthed
            invite={invite}
            onComplete={this._closeDialog}
            _onUpdate={this._onUpdate}
            history={this.props.history}
          />
        )}
      </Container>
    );
  }
}

RsvpContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  main: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  main: state.main,
});
export default connect(mapStateToProps)(withStyles(styles)(RsvpContainer));
