const capitalizeFirstChar = (str) => {
  if (typeof str !== "string") return null;
  return str[0].toUpperCase() + str.slice(1, str.length);
};

const capitalizeAllFirstChar = (str) => {
  if (typeof str !== "string") return "";
  return str
    .split(" ")
    .map((str) => {
      if (str.length > 0) {
        return capitalizeFirstChar(str);
      }
      return str;
    })
    .join(" ");
};

const isEmpty = (str) => {
  return str.trim() === "";
};

const isEmail = (email) => {
  const emailRegEx =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(emailRegEx)) return true;
  else return false;
};

const isPhoneNum = (phoneNum) => {
  let _phoneNum = phoneNum;
  if (typeof phoneNum === "number") _phoneNum = _phoneNum.toString();
  if (typeof _phoneNum !== "string") return false;
  const phoneNumRegEx =
    // eslint-disable-next-line
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (_phoneNum.match(phoneNumRegEx)) return true;
  else return false;
};

const splitStringToArray = (str) => {
  const strArray = str
    .toLowerCase()
    .split(" ")
    .filter((term) => !isEmpty(term));

  return strArray;
};

const getRandomLetter = () => {
  const alph = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
  return alph[Math.floor(Math.random() * 26)];
};

const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export {
  capitalizeFirstChar,
  capitalizeAllFirstChar,
  isEmpty,
  isPhoneNum,
  isEmail,
  splitStringToArray,
  getRandomLetter,
  shuffleArray,
};
