import React, { useState, useEffect } from "react";
import Firebase from "../../../Firebase";
import { useDispatch } from "react-redux";
import { SET_ALERT, SET_INVITE } from "../../../redux/types";

// Component imports
import { capitalizeAllFirstChar, isEmpty } from "../../../helpFunctions";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Fade from "@mui/material/Fade";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  ...theme.spreadThis,
}));

function RsvpInd({ invite }) {
  const [plusOne, setPlusOne] = useState(false);
  const [disableWhileLoad, setDisableWhileLoad] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState("-");
  const [event, setEvent] = useState([]);
  const [meal, setMeal] = useState("");
  const [dietary, setDietary] = useState("");
  const [rerender, setRerender] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [attendees, setAttendees] = useState(invite.attendees);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (invite.attendees.every((attendee) => attendee.status === ""))
      setDisableWhileLoad(false);
    if (invite.attendees.length > 1) {
      setPlusOne(true);
      setFirstName(invite.attendees[1].firstName);
      setLastName(invite.attendees[1].lastName);
      setTitle(invite.attendees[1].title);
      setEvent(invite.attendees[1].event);
      setMeal(invite.attendees[1].meal);
    }
    if (invite.dietary) setDietary(invite.dietary);
  }, []);

  const handlePlusOne = () => {
    if (!plusOne) {
      if (invite.attendees.length > 1) {
        setFirstName(invite.attendees[1].firstName);
        setLastName(invite.attendees[1].lastName);
        setTitle(invite.attendees[1].title);
        setEvent(invite.attendees[1].event);
        setMeal(invite.attendees[1].meal);
      }
    } else {
      setFirstName("");
      setLastName("");
      setTitle("-");
      setEvent([]);
      setMeal("");
    }
    setPlusOne(!plusOne);
    setErrors({ ...errors, firstName: false, lastName: false });
  };

  const updateStatusSingle = (e, index) => {
    let _attendees = attendees;
    _attendees[index] = {
      ..._attendees[index],
      status: e.target.value,
      event: e.target.value === "accepted" ? ["ceremony", "reception"] : [],
    };
    setAttendees(_attendees);
    setRerender(!rerender);
  };

  const updateEventSingle = (e, index) => {
    let _attendees = attendees;

    const event = _attendees[index].event;

    if (event.includes(e.target.value)) {
      const eventInd = event.findIndex((ev) => ev === e.target.value);
      event.splice(eventInd, 1);
    } else {
      event.push(e.target.value);
    }

    _attendees[index] = { ..._attendees[index], event };
    setAttendees(_attendees);
    setRerender(!rerender);
  };

  const updateEventPlusOne = (e) => {
    const _event = event;
    if (_event.includes(e.target.value)) {
      const eventInd = _event.findIndex((ev) => ev === e.target.value);
      _event.splice(eventInd, 1);
    } else {
      _event.push(e.target.value);
    }

    setEvent(_event);
    setRerender(!rerender);
  };

  const updateMealSingle = (e, index) => {
    let _attendees = attendees;
    const meal = e.target.value;

    _attendees[index].meal = meal;
    setAttendees(_attendees);
    setRerender(!rerender);
  };

  const handleSetTitle = (e) => {
    setTitle(e.target.value);
    const _errors = { ...errors };
    delete _errors.title;
    setErrors(_errors);
  };

  // Upload invite
  const handleUpdateInvite = async () => {
    setDisableWhileLoad(true);
    setErrors({});
    const isValid = validateInput();
    if (isValid) {
      const updatedInvite = setInvite();

      await updateFBInvite(updatedInvite);
      await updateNotification(updatedInvite);
      setDisableWhileLoad(false);
    } else {
      setDisableWhileLoad(false);
    }
  };

  const updateNotification = async (updatedInvite) => {
    const db = Firebase.firestore();

    const notificationRef = db.collection("notifications");

    try {
      await notificationRef.add({
        refId: invite.id,
        groupName: updatedInvite.groupName,
        type: "invite",
        status:
          invite.status === updatedInvite.status
            ? "updated"
            : updatedInvite.status,
        createdAt: new Date().toISOString(),
      });
      return;
    } catch (err) {
      console.error(err);
    }
  };

  const updateFBInvite = async (updatedInvite) => {
    const db = Firebase.firestore();

    try {
      await db.doc(`/invites/${invite.id}`).update(updatedInvite);
      const _invite = { ...invite, ...updatedInvite };
      dispatch({ type: SET_INVITE, payload: _invite });
      dispatch({
        type: SET_ALERT,
        payload: {
          message: "Successfully updated your invite",
          type: "success",
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setDisableWhileLoad(false);
    }
  };

  const setInvite = () => {
    const updateInvite = {
      numResponded: 1,
      dietary,
    };

    updateInvite.status = invite.attendees[0].status;

    updateInvite.attendees = [invite.attendees[0]];
    if (plusOne) {
      updateInvite.attendees.push({
        title,
        firstName: firstName.toLowerCase(),
        lastName: lastName.toLowerCase(),
        status: "accepted",
        event,
        meal,
      });
      updateInvite.numResponded = 2;
      updateInvite.groupName = `${capitalizeAllFirstChar(
        updateInvite.attendees[0].title
      )} ${capitalizeAllFirstChar(
        updateInvite.attendees[0].firstName
      )} ${capitalizeAllFirstChar(
        updateInvite.attendees[0].lastName
      )} & ${capitalizeAllFirstChar(
        updateInvite.attendees[1].title
      )} ${capitalizeAllFirstChar(
        updateInvite.attendees[1].firstName
      )} ${capitalizeAllFirstChar(updateInvite.attendees[1].lastName)}`;
    } else {
      updateInvite.attendees = [invite.attendees[0]];
      updateInvite.groupName = `${capitalizeAllFirstChar(
        updateInvite.attendees[0].title
      )} ${capitalizeAllFirstChar(
        updateInvite.attendees[0].firstName
      )} ${capitalizeAllFirstChar(updateInvite.attendees[0].lastName)}`;
    }

    updateInvite.keyTermsQuery = _setKeyWords(updateInvite.attendees);
    return updateInvite;
  };

  const _setKeyWords = (attendees) => {
    const keyTermsQuery = [];

    attendees.forEach((attendee) => {
      const keyTerms = [
        ...attendee.title.split(" "),
        ...attendee.firstName.split(" "),
        ...attendee.lastName.split(" "),
      ];
      keyTerms.forEach((term) => {
        if (keyTermsQuery.indexOf(term) < 0) {
          keyTermsQuery.push(term);
        }
      });
    });

    return keyTermsQuery.filter((term) => !isEmpty(term));
  };

  const validateInput = () => {
    const _errors = {};

    if (isEmpty(attendees[0].status))
      _errors.status = "Invalid RSVP response. Please RSVP for all guests.";
    if (attendees[0].status === "accepted" && attendees[0].event.length < 1) {
      _errors.event =
        "You must select at least one event for every accepted guest.";
    }
    if (
      attendees[0].event.includes("reception") &&
      isEmpty(attendees[0].meal)
    ) {
      _errors.meal =
        "You must select an entrée for all guests attending the reception.";
    }
    if (plusOne) {
      if (isEmpty(title) || title === "-") {
        _errors.title = "Please select a title";
      }
      if (isEmpty(firstName)) {
        _errors.firstName = "Please enter a first name";
      }
      if (isEmpty(lastName)) {
        _errors.lastName = "Please enter a last name";
      }
      if (event.length < 1) {
        _errors.event =
          "You must select at least one event for every accepted guest.";
      }
      if (event.includes("reception") && isEmpty(meal)) {
        _errors.meal =
          "You must select an entrée for all guests attending the reception.";
      }
    }

    setErrors(_errors);
    return Object.keys(_errors).length < 1;
  };

  return (
    <>
      <Box className="rsvp-options" justifyContent="center" p={2}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={10}>
            <Box
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                marginTop: 2,
                padding: "2rem 1.5rem",
              }}
            >
              <Typography align="center" variant="h4">
                {capitalizeAllFirstChar(attendees[0].title)}{" "}
                {capitalizeAllFirstChar(attendees[0].firstName)}{" "}
                {capitalizeAllFirstChar(attendees[0].lastName)}
              </Typography>

              <RadioGroup
                row
                aria-label="status"
                name="status"
                value={attendees[0].status}
                onChange={(e) => updateStatusSingle(e, 0)}
              >
                <Grid
                  container
                  spacing={0}
                  justifyContent="space-evenly"
                  sx={{ marginTop: 1 }}
                >
                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControlLabel
                      value="accepted"
                      control={
                        <Radio
                          color={"secondary"}
                          disabled={disableWhileLoad}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color={
                            attendees[0].status === "accepted"
                              ? "secondary"
                              : "textSecondary"
                          }
                        >
                          Happily Accepts
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={"auto"} sm={"auto"}>
                    <FormControlLabel
                      value="declined"
                      control={
                        <Radio
                          color={"secondary"}
                          disabled={disableWhileLoad}
                        />
                      }
                      label={
                        <Typography
                          color={
                            attendees[0].status === "declined"
                              ? "secondary"
                              : "textSecondary"
                          }
                          variant="body2"
                        >
                          Regretfully Declines
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <Fade
                in={attendees[0].status === "accepted"}
                mountOnEnter
                unmountOnExit
              >
                <Box
                  sx={{
                    borderTop: "1px solid #f4bc5a",
                    marginTop: 2,
                    paddingTop: 2,
                  }}
                >
                  <Typography align="center" variant="h6">
                    I will be attending the...
                  </Typography>

                  <Grid container spacing={0} justifyContent="space-evenly">
                    <Grid item xs={"auto"} sm={"auto"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color={"secondary"}
                            value="ceremony"
                            checked={attendees[0].event.includes("ceremony")}
                            onChange={(e) => updateEventSingle(e, 0)}
                            disabled={disableWhileLoad}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            color={
                              attendees[0].event.includes("ceremony")
                                ? "secondary"
                                : "textSecondary"
                            }
                          >
                            Ceremony (4:30)
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={"auto"} sm={"auto"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="reception"
                            color={"secondary"}
                            checked={attendees[0].event.includes("reception")}
                            onChange={(e) => updateEventSingle(e, 0)}
                            disabled={disableWhileLoad}
                          />
                        }
                        label={
                          <Typography
                            color={
                              attendees[0].event.includes("reception")
                                ? "secondary"
                                : "textSecondary"
                            }
                            variant="body2"
                          >
                            Reception (6:30)
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Fade>
              <Fade
                in={attendees[0].event.includes("reception")}
                mountOnEnter
                unmountOnExit
              >
                <Box
                  sx={{
                    paddingTop: 2,
                  }}
                >
                  <Typography align="center" variant="h6">
                    My choice of entrée is...
                  </Typography>

                  <RadioGroup
                    row
                    aria-label="meal"
                    name="meal"
                    value={attendees[0].meal}
                    onChange={(e) => updateMealSingle(e, 0)}
                    style={{ marginBottom: "2rem" }}
                  >
                    <Grid container spacing={0} justifyContent="space-evenly">
                      <Grid item xs={"auto"} sm={"auto"}>
                        <FormControlLabel
                          value="Halibut"
                          control={
                            <Radio
                              color={"secondary"}
                              disabled={disableWhileLoad}
                            />
                          }
                          label={
                            <Typography
                              color={
                                attendees[0].meal === "Halibut"
                                  ? "secondary"
                                  : "textSecondary"
                              }
                              variant="body2"
                              style={{ marginBottom: 0 }}
                            >
                              West Coast Halibut
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={"auto"} sm={"auto"}>
                        <FormControlLabel
                          value="Steak"
                          control={
                            <Radio
                              color={"secondary"}
                              disabled={disableWhileLoad}
                            />
                          }
                          label={
                            <Typography
                              variant="body2"
                              style={{ marginBottom: 0 }}
                              color={
                                attendees[0].meal === "Steak"
                                  ? "secondary"
                                  : "textSecondary"
                              }
                            >
                              New York Steak
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Box>
              </Fade>
            </Box>
          </Grid>
          <Fade in={plusOne} mountOnEnter unmountOnExit>
            <Grid item xs={12} sm={10}>
              <Box
                sx={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  marginTop: 2,
                  padding: "1rem 1.5rem",
                }}
              >
                <Typography variant="h6" align="center">
                  My Plus One
                </Typography>

                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={10} sm={12}>
                    <Select
                      variant="outlined"
                      color="secondary"
                      size="small"
                      value={title}
                      onChange={handleSetTitle}
                      sx={{ width: 100 }}
                      inputProps={{
                        name: "title",
                        id: "controlled-native",
                      }}
                      placeholder="Title"
                      error={Boolean(errors.title)}
                      className={
                        errors.title
                          ? classes.styledTextFieldError
                          : classes.styledTextField
                      }
                    >
                      <MenuItem value={"-"}>Title</MenuItem>
                      <MenuItem value={"mr."}>Mr.</MenuItem>
                      <MenuItem value={"ms."}>Ms.</MenuItem>
                      <MenuItem value={"mrs."}>Mrs.</MenuItem>
                      <MenuItem value={"dr."}>Dr.</MenuItem>
                      <MenuItem value={"pastor"}>Pastor</MenuItem>
                    </Select>
                    <Typography
                      style={{ margin: "0px 14px" }}
                      color="error"
                      variant="caption"
                      component="div"
                    >
                      {errors.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={6}>
                    <TextField
                      name="firstName"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        const _errors = errors;
                        delete _errors.firstName;
                        setErrors(_errors);
                      }}
                      fullWidth
                      color="secondary"
                      autoFocus
                      size="small"
                      placeholder="First Name*"
                      className={
                        errors.firstName
                          ? classes.styledTextFieldError
                          : classes.styledTextField
                      }
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName}
                      variant="outlined"
                      disabled={disableWhileLoad}
                    />
                  </Grid>
                  <Grid item xs={10} sm={6}>
                    <TextField
                      name="lastName"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        const _errors = errors;
                        delete _errors.lastName;
                        setErrors(_errors);
                      }}
                      fullWidth
                      color="secondary"
                      size="small"
                      placeholder="Last Name*"
                      className={
                        errors.lastName
                          ? classes.styledTextFieldError
                          : classes.styledTextField
                      }
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName}
                      variant="outlined"
                      disabled={disableWhileLoad}
                    />
                  </Grid>
                </Grid>
                <Fade
                  in={
                    !isEmpty(firstName) &&
                    !isEmpty(lastName) &&
                    !isEmpty(title) &&
                    title !== "-"
                  }
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <Fade in mountOnEnter unmountOnExit>
                      <Box
                        sx={{
                          borderTop: "1px solid #f4bc5a",
                          marginTop: 2,
                          paddingTop: 2,
                        }}
                      >
                        <Typography align="center" variant="h6">
                          I will be attending the...
                        </Typography>

                        <Grid
                          container
                          spacing={0}
                          justifyContent="space-evenly"
                        >
                          <Grid item xs={"auto"} sm={"auto"}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color={"secondary"}
                                  value="ceremony"
                                  checked={event.includes("ceremony")}
                                  onChange={(e) => updateEventPlusOne(e)}
                                  disabled={disableWhileLoad}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  color={
                                    event.includes("ceremony")
                                      ? "secondary"
                                      : "textSecondary"
                                  }
                                >
                                  Ceremony (4:30)
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={"auto"} sm={"auto"}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="reception"
                                  color={"secondary"}
                                  checked={event.includes("reception")}
                                  onChange={(e) => updateEventPlusOne(e)}
                                  disabled={disableWhileLoad}
                                />
                              }
                              label={
                                <Typography
                                  color={
                                    event.includes("reception")
                                      ? "secondary"
                                      : "textSecondary"
                                  }
                                  variant="body2"
                                >
                                  Reception (6:30)
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Fade>
                    <Fade
                      in={event.includes("reception")}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          paddingTop: 2,
                        }}
                      >
                        <Typography align="center" variant="h6">
                          My choice of entrée is...
                        </Typography>

                        <RadioGroup
                          row
                          aria-label="meal"
                          name="meal"
                          value={meal}
                          onChange={(e) => setMeal(e.target.value)}
                          style={{ marginBottom: "2rem" }}
                        >
                          <Grid
                            container
                            spacing={0}
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={"auto"} sm={"auto"}>
                              <FormControlLabel
                                value="Halibut"
                                control={
                                  <Radio
                                    color={"secondary"}
                                    disabled={disableWhileLoad}
                                  />
                                }
                                label={
                                  <Typography
                                    color={
                                      meal === "Halibut"
                                        ? "secondary"
                                        : "textSecondary"
                                    }
                                    variant="body2"
                                    style={{ marginBottom: 0 }}
                                  >
                                    West Coast Halibut
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item xs={"auto"} sm={"auto"}>
                              <FormControlLabel
                                value="Steak"
                                control={
                                  <Radio
                                    color={"secondary"}
                                    disabled={disableWhileLoad}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body2"
                                    style={{ marginBottom: 0 }}
                                    color={
                                      meal === "Steak"
                                        ? "secondary"
                                        : "textSecondary"
                                    }
                                  >
                                    New York Steak
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </Box>
                    </Fade>
                  </div>
                </Fade>
              </Box>
            </Grid>
          </Fade>
          <Fade
            in={!isEmpty(meal) || !isEmpty(attendees[0].meal)}
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12} sm={10}>
              <Box
                sx={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  marginTop: 2,
                  padding: "1rem 1.5rem",
                }}
              >
                <TextField
                  name="dietary"
                  value={dietary}
                  onChange={(e) => {
                    if (e.target.value.length < 141) setDietary(e.target.value);
                  }}
                  fullWidth
                  color="secondary"
                  size="small"
                  multiline
                  minRows={3}
                  maxRows={5}
                  label="Dietary Restrictions"
                  placeholder="Please enter your dietary restrictions (140 char max)"
                  className={
                    errors.dietary
                      ? classes.styledTextFieldError
                      : classes.styledTextField
                  }
                  error={Boolean(errors.dietary)}
                  helperText={errors.dietary}
                  variant="outlined"
                  disabled={disableWhileLoad}
                />
              </Box>
            </Grid>
          </Fade>
        </Grid>
      </Box>
      <Typography
        variant="body2"
        align="center"
        style={{ margin: "1rem 0rem" }}
      >
        Attire: Suits for men and dark or pastel formal dresses for women
      </Typography>
      <Fade
        in={
          Boolean(errors.status) ||
          Boolean(errors.event) ||
          Boolean(errors.meal)
        }
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Typography
            component="div"
            variant="caption"
            color="error"
            align="center"
          >
            {errors.status}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="error"
            align="center"
          >
            {errors.event}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="error"
            align="center"
          >
            {errors.meal}
          </Typography>
        </div>
      </Fade>
      <Grid
        container
        justifyContent="flex-end"
        spacing={1}
        style={{ marginTop: "1rem" }}
      >
        {invite.allowPlusOne && (
          <Grid item>
            <Button
              variant={"outlined"}
              color="secondary"
              disabled={disableWhileLoad}
              onClick={handlePlusOne}
              sx={{
                borderRadius: 0,
                boxShadow: "none",
                backgroundColor: "#fff",
                color: "secondary.main",
                "&:hover": {
                  color: "#fff",
                  boxShadow: "none",
                  backgroundColor: "secondary.main",
                },
              }}
              startIcon={<AddIcon />}
            >
              {plusOne && invite.numResponded > 1
                ? "Remove Plus One"
                : "Add a Plus One"}
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="outlined"
            disabled={disableWhileLoad}
            type="submit"
            color="secondary"
            onClick={handleUpdateInvite}
            sx={{
              borderRadius: 0,
              boxShadow: "none",
              color: "#fff",
              width: 100,
              backgroundColor: "secondary.main",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "#fff",
                color: "secondary.main",
              },
            }}
          >
            confirm
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default RsvpInd;
